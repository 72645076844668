<template lang="pug">
    .main-wrapper.motivo-estorno-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Motivos de Estorno</b>

        Dialog.dialog-salvar(:header="`${ model.id ? 'Editar' : 'Adicionar' } Estorno`"
            :visible.sync='dialogSalvar' :modal='true' @hide="model={ ds_motivo: '' }; submitted=false")
            form(@submit.prevent='salvarMotivo()')
                .p-grid.p-fluid.p-align-end(style='margin: 0 auto; max-width: 500px;')

                    .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ds_motivo.$error }")
                        label.form-label Descrição:
                        InputText(type='text' v-model='$v.model.ds_motivo.$model')
                        .feedback--errors(v-if='submitted && $v.model.ds_motivo.$error')
                            .form-message--error(v-if="!$v.model.ds_motivo.minLength") Deve ter pelo menos 2 caracteres.
                            .form-message--error(v-if="!$v.model.ds_motivo.required") Campo obrigatório.

                    .p-col-12.mt-2(v-if="model.id")
                        .p-grid
                            .p-col-12.p-md-6.aux-2
                                span <b>Usuário criação: </b> {{ model.cd_usuario_cri.username }}
                            .p-col-12.p-md-6.aux-2
                                span <b>Data da criação: </b> {{ model.dt_criado_f }}
                            .p-col-12.p-md-6.aux-2
                                span <b>Usuário edição: </b> {{ model.cd_usuario_edi.username }}
                            .p-col-12.p-md-6.aux-2
                                span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                    .p-col-12.mt-2
                        ProgressSpinner.spinner-small(v-if='waitingDialogSalvar' strokeWidth='6')
                        Button(v-else label='Salvar' type="submit")

        Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
            .p-grid.p-fluid
                .p-col-12.p-md-6
                    label.form-label Status:
                        Dropdown(
                            v-model='filters.ie_status'
                            :options='filtersOptions.ieStatus'
                            optionLabel='text'
                            optionValue='value'
                            dataKey='value'
                            placeholder='TODOS'
                            @change='applyFilters()'
                        )
        .ta-right.mb-4
            Button(label='Adicionar' icon="jam jam-plus" @click="dialogSalvar=true")

        ProgressBar(v-if='waiting' mode="indeterminate")

        div(v-else-if='!list.length')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.

        div(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="props.data.nm_caixa" style='position: relative')
                            .ta-left
                                p <b>Descrição:</b> {{ props.data.ds_motivo }}

            Panel.datatable(header='Lista de Motivos')
                DataTable(:value="list")
                    Column(headerStyle='width: 5%;' bodyStyle='text-align: center;')
                        template(#body='props')
                            ProgressSpinner.spinner-small(v-if='waitingStatusId === props.data.id' strokeWidth='6')
                            .status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click='alterarStatus(props.data)')
                                i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
                    Column(headerStyle='width: 80%;' field='ds_motivo' header='Descrição')
                    Column(headerStyle='width: 20%; text-align: center' header='Ações')
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click="model=props.data;dialogSalvar=true"
                                )

</template>

<style lang="scss">
    .motivo-estorno-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
        }
        .dialog-salvar {
            width: 600px;
            max-width: 98%;
        }
        .spinner-small {
            max-width: 23px;
            height: auto;
        }
        .status-indicator {
            display: inline-block;
            font-size: 0;
            width: 23px;
            height: 23px;
            line-height: 36px;
            border-radius: 50%;
            background-color: #94c860;
            border: 1px solid #84b553;
            transition: 250ms;
            cursor: pointer;
            padding-right:10px;
            &:hover { background-color: #84b553; }
            &.off {
                background-color: #d33838;
                border: 1px solid #b33030;
                &:hover { background-color: #b33030; }
            }
            .jam {
                color: #fff;
                margin-left:2px;
            }
        }
    }
</style>

<script>
    import Button from "primevue/button"
    import Column from "primevue/column"
    import DataTable from "primevue/datatable"
    import DataView from "primevue/dataview"
    import Dialog from "primevue/dialog"
    import InputText from 'primevue/inputtext'
    import Panel from "primevue/panel"
    import ProgressBar from "primevue/progressbar"
    import ProgressSpinner from 'primevue/progressspinner'
    import Tooltip from "primevue/tooltip"
    import Dropdown from 'primevue/dropdown'
    import wsConfigs from './../../middleware/configs'

    import {Caixas} from "@/middleware"
    import {minLength, required} from "vuelidate/lib/validators"
    import moment from "moment"

    export default {
        components: { Button, Column, DataTable, DataView, Dialog,
            InputText, Panel, ProgressBar, ProgressSpinner, Tooltip,
            Dropdown },
        directives: { tooltip: Tooltip },
        created() {
            this.applyFilters()
            // this.getList()
        },
        data() {
            return {
                list: [],
                waiting: false,
                windowInnerWidth: window.innerWidth,
                waitingStatusId: 0,
                model: {
                    ds_motivo: "",
                },
                submitted: false,
                dialogSalvar: false,
                waitingDialogSalvar: false,
                filters: {
                    ie_status: 'true'
				},
                filtersOptions: {
                    ieStatus: [
                        { text: "TODOS", value: null },
                        { text: "Ativo", value: 'true' },
                        { text: "Inativo", value: 'false' },
                    ]
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                }
            }
        },
        validations () {
            let v = {
                model: {
                    ds_motivo: { required, minLength: minLength(2) },
                }
            }
            return v
        },
        methods: {
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
                this.getList(params)
            },
            getList(params) {
                this.waiting = true
                Caixas.findAllMotivoEstorno(params).then(response => {
                    this.waiting = false
                    if (response.status === 200) {
                        this.list = response.data
                        this.list.forEach(motivo => {
                            motivo.cd_usuario_cri = motivo.cd_usuario_cri || {}
                            motivo.cd_usuario_edi = motivo.cd_usuario_edi || {}
                            if (motivo.dt_criado) {
                                motivo.dt_criado_f = moment(motivo.dt_criado).format('DD/MM/YYYY HH:mm')
                            }
                            if (motivo.dt_atualizado) {
                                motivo.dt_atualizado_f = moment(motivo.dt_atualizado).format('DD/MM/YYYY HH:mm')
                            }
                        })
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            salvarMotivo() {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = {ds_motivo: this.model.ds_motivo}
                if (this.model.id) dataSend.id = this.model.id

                this.waitingDialogSalvar = true
                Caixas.saveMotivoEstorno(dataSend).then(response => {
                    this.waitingDialogSalvar = false
                    if ([200, 201, 204].includes(response.status)) {
                        this.$toast.success("Motivo salvo com sucesso.")
                        this.model = { ds_motivo: "" }
                        this.dialogSalvar = false
                        this.getList()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })

            },
            alterarStatus(motivo) {
                let dataSend = {id: motivo.id, ie_status: !motivo.ie_status}

                this.waitingStatusId = motivo.id
                Caixas.saveMotivoEstorno(dataSend).then(response => {
                    this.waitingStatusId = 0
                    if ([200, 201, 204].includes(response.status)) {
                        motivo.ie_status = !motivo.ie_status
                        this.$toast.success("Status alterado com sucesso.")
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
        }
    }
</script>